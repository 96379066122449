<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/register.jpg" alt="register" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="px-8 pt-8 register-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Create Account For Doctor</h4>
                  <p>Fill the below form to create a new account.</p>
                </div>

                <vs-alert
                  :active.sync="showDismissibleAlert"
                  closable
                  icon-pack="feather"
                  close-icon="icon-x"
                >{{ flashMessage }}</vs-alert>
                <vs-tabs :value="tabIndex">
                  <!--                  step 1-->
                  <vs-tab label="Step 1" v-on:click="switchTab(0)">
                    <div class="clearfix">
                      <br />

                      <vs-input
                        :danger="errors.first('firstName')"
                        :success="!errors.first('firstName') && firstName != ''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="first name"
                        name="firstName"
                        label-placeholder="First Name"
                        placeholder="First Name"
                        v-model="firstName"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("firstName")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('lastName')"
                        :success="!errors.first('lastName') && lastName != ''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="last name"
                        name="lastName"
                        label-placeholder="Last Name"
                        placeholder="Last Name"
                        v-model="lastName"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("lastName")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('email')"
                        :success="!errors.first('email') && email != ''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required|email'"
                        data-vv-validate-on="blur"
                        name="email"
                        type="email"
                        label-placeholder="Email"
                        placeholder="Email"
                        v-model="email"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("email")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('password')"
                        :success="!errors.first('password') && password != ''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        ref="password"
                        type="password"
                        data-vv-name="password"
                        data-vv-validate-on="blur"
                        v-validate="{
                          required: true,
                          min: 8,
                          regex: /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9._*\W]+$)/
                        }"
                        name="password"
                        label-placeholder="Password"
                        placeholder="Password"
                        v-model="password"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("password")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('confirm_password')"
                        :success="
                          !errors.first('confirm_password') &&
                            confirm_password != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        type="password"
                        v-validate="'confirmed:password'"
                        data-vv-validate-on="blur"
                        data-vv-as="password"
                        name="confirm_password"
                        label-placeholder="Confirm Password"
                        placeholder="Confirm Password"
                        v-model="confirm_password"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("confirm_password")
                        }}
                      </span>
                      <vs-button class="float-right mt-6" @click="nextTab()">Next</vs-button>
                    </div>
                  </vs-tab>
                  <!--                  end of step1 -->

                  <!--                  step 2-->
                  <vs-tab label="Step 2" v-on:click="switchTab(1)">
                    <div class="clearfix">
                      <br />
                      <vs-input
                        :danger="errors.first('preferredName')"
                        :success="
                          !errors.first('preferredName') && preferredName != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate
                        data-vv-validate-on="blur"
                        data-vv-as="preferred name"
                        name="preferredName"
                        label-placeholder="preferred Name"
                        placeholder="Preferred Name(Optional)"
                        v-model="preferredName"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("preferredName")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('contactNumber')"
                        :success="
                          !errors.first('contactNumber') && contactNumber != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="contact number"
                        name="contactNumber"
                        label-placeholder="Contact Number"
                        placeholder="Contact Number"
                        v-model="contactNumber"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("contactNumber")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('prescriberNumber')"
                        :success="
                          !errors.first('prescriberNumber') &&
                            prescriberNumber != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="Prescriber Number "
                        name="prescriberNumber"
                        label-placeholder="Prescriber Number"
                        placeholder="Prescriber Number"
                        v-model="prescriberNumber"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("prescriberNumber")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('ahpraRegistrationNumber')"
                        :success="
                          !errors.first('ahpraRegistrationNumber') &&
                            ahpraRegistrationNumber != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="AHPRA Registration Number"
                        name="ahpraRegistrationNumber"
                        label-placeholder="AHPRA Registration Number"
                        placeholder="AHPRA Registration Number"
                        v-model="ahpraRegistrationNumber"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("ahpraRegistrationNumber")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('ahpraExiryDate')"
                        :success="
                          !errors.first('ahpraExiryDate') &&
                            ahpraExiryDate != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        type="text"
                        onfocus="(this.type='date')"
                        onblur="if(!this.value)this.type='text'"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="APHRA expiry date"
                        name="ahpraExiryDate"
                        label-placeholder="APHRA expiry date"
                        placeholder="APHRA expiry date"
                        v-model="ahpraExiryDate"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("ahpraExiryDate")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('indemnityInsuranceProvider')"
                        :success="
                          !errors.first('indemnityInsuranceProvider') &&
                            indemnityInsuranceProvider != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="indemnity insurance provider"
                        label-placeholder="Indemnity Insurance Provider"
                        name="indemnityInsuranceProvider"
                        placeholder="Indemnity Insurance Provider"
                        v-model="indemnityInsuranceProvider"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("indemnityInsuranceProvider")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('indemnityInsuranceNumber')"
                        :success="
                          !errors.first('indemnityInsuranceNumber') &&
                            indemnityInsuranceNumber != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        data-vv-as="indemnity insurance number"
                        label-placeholder="Indemnity Insurance Number"
                        name="indemnityInsuranceNumber"
                        placeholder="Indemnity Insurance Number"
                        v-model="indemnityInsuranceNumber"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("indemnityInsuranceNumber")
                        }}
                      </span>

                      <vs-input
                        :danger="errors.first('indemnityInsuranceExpiry')"
                        :success="
                          !errors.first('indemnityInsuranceExpiry') &&
                            indemnityInsuranceExpiry != ''
                        "
                        val-icon-success="done"
                        val-icon-danger="error"
                        type="text"
                        onfocus="(this.type='date')"
                        onblur="if(!this.value)this.type='text'"
                        v-validate="'required|date_format:YYYY-MM-DD'"
                        data-vv-validate-on="blur"
                        data-vv-as="indemnity insurance expiry"
                        name="indemnityInsuranceExpiry"
                        label-placeholder="Indemnity Insurance Expiry"
                        placeholder="Indemnity Insurance Expiry"
                        v-model="indemnityInsuranceExpiry"
                        class="w-full mt-6"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("indemnityInsuranceExpiry")
                        }}
                      </span>
                      <br />
                      <label>Attach AHPRA Certificate</label>
                      <vs-upload
                        automatic
                        name="aphraCertificate"
                        action="/api/v1/doctors/upload"
                        text="Attach Document"
                        limit="1"
                        @on-delete="deleteFile('a')"
                        @on-success="successUpload('a', $event)"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("aphraCertificate")
                        }}
                      </span>
                      <br />

                      <label>Attach Insurance Certificate</label>
                      <vs-upload
                        automatic
                        name="insuranceCertificate"
                        action="/api/v1/doctors/upload"
                        text="Attach Document"
                        limit="1"
                        @on-delete="deleteFile('i')"
                        @on-success="successUpload('i', $event)"
                      />
                      <span class="text-danger text-sm">
                        {{
                        errors.first("insuranceCertificate")
                        }}
                      </span>
                      <br />

                      <label>Attach Electronic Signature</label>
                      <vs-upload
                        automatic
                        name="electronicSignature"
                        action="/api/v1/doctors/upload"
                        text="Attach Document"
                        limit="1"
                        @on-delete="deleteFile('s')"
                        @on-success="successUpload('s', $event)"
                      />

                      <span class="text-danger text-sm">
                        {{
                        errors.first("electronicsSignature")
                        }}
                      </span>

                      <vs-upload
                        automatic
                        name="createSignature"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        text="Create Signature"
                        limit="1"
                        @on-delete="deleteFile"
                        @on-success="successUpload"
                      />

                      <span class="text-danger text-sm">
                        {{
                        errors.first("createSignature")
                        }}
                      </span>

                      <vs-checkbox
                        v-model="isTermsConditionAccepted"
                        class="mt-6"
                      >I accept the terms & conditions.</vs-checkbox>
                      <vs-button
                        class="float-right mt-6"
                        @click="registerUserJWt"
                        :disabled="!validateForm"
                      >Register</vs-button>
                    </div>
                  </vs-tab>
                  <!--                  end of step2 -->
                </vs-tabs>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoctorRegister",
  data() {
    return {
      tabIndex: 0,
      isDisabled: true,
      registerType: "doctor",
      flashMessage: "",
      showDismissibleAlert: false,
      firstName: "",
      lastName: "",
      companyName: "",
      clinicName: "",
      address: { displayAddress: "" },
      isNurseInjecter: false,
      abn: "",
      email: "",
      password: "",
      confirm_password: "",
      postalAddress: "",
      bestContactName: "",
      bestContactEmail: "",
      bestContactNumber: "",
      isTermsConditionAccepted: true,
      invoiceEmail: "",

      preferredName: "",
      contactNumber: "",
      prescriberNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      signature: "",
    };
  },
  methods: {
    successUpload(tab, event) {
      const response = JSON.parse(event.originalTarget.response);
      if (tab == "a") this.ahpraCertificate = response.Location;
      if (tab == "i") this.insuranceCertificate = response.Location;
      if (tab == "s") this.signature = response.Location;
    },
    deleteFile(tab) {
      if (tab == "a") this.ahpraCertificate = "";
      if (tab == "i") this.insuranceCertificate = "";
      if (tab == "s") this.signature = "";
    },
    async switchTab(tabIndex) {
      let self = this;
      if (tabIndex == 1) {
        self.tabIndex = 1;
        const valid = await this.$validator.validate();
        if (!valid) {
          // this.isDisabled = null
          this.flashMessage = "Complete step 1 first";
          this.showDismissibleAlert = true;
          this.tabIndex = 0;
        }
      }
    },
    //check here
    async nextTab() {
      this.tabIndex = 0;
      const valid = await this.$validator.validate();
      if (!valid) {
        this.flashMessage = "Complete step 1 first";
        this.showDismissibleAlert = true;
        return false;
      }

      this.$store
        .dispatch("clinic/checkEmail", this.email)
        .then((res) => {
          this.tabIndex = 1;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors.add({
              field: "email",
              msg: err.response.data.message,
            });
          } else {
            this.$vs.notify({
              title: "Register Attempt",
              text: "Server Error",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          }
        });
    },
    async registerUserJWt() {
      // If form is not validated or user is already login return
      // if (!this.validateForm || !this.checkLogin()) return
      const valid = await this.$validator.validate();
      if (!valid) {
        return false;
      }
      const payload = {
        userDetails: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,

          preferredName: this.preferredName,
          contactNumber: this.contactNumber,
          prescriberNumber: this.prescriberNumber,
          ahpraRegistrationNumber: this.ahpraRegistrationNumber,
          ahpraExiryDate: this.ahpraExiryDate,
          indemnityInsuranceProvider: this.indemnityInsuranceProvider,
          indemnityInsuranceNumber: this.indemnityInsuranceNumber,
          indemnityInsuranceExpiry: this.indemnityInsuranceExpiry,
          ahpraCertificate: this.ahpraCertificate,
          insuranceCertificate: this.insuranceCertificate,
          signature: this.signature,
          userType: "doctor",
        },
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch("clinic/registerUserJWT", payload)
        .then((res) => {
          this.$vs.notify({
            title: "Doctor Registration",
            text: "Doctor Registered Successfully.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        })
        .catch((err) => {
          this.flashMessage = err.response.data.message;
          this.showDismissibleAlert = true;
          this.$vs.notify({
            title: "Register Attempt",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        });
    },
  },
  computed: {
    validateForm() {
      return this.isTermsConditionAccepted === true;
    },
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
</style>
